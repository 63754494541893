<template>
  <div :class="['target__wrapper', {'target__wrapper-fullscreen': isFullScreen}]">
    <button
      type="button"
      class="v-btn v-btn--icon v-btn--round theme--light v-size--default mt-2"
      title="Развернуть на весь экран"
      @click="fullscreen"
    >
      <span class="v-btn__content">
        <i
          aria-hidden="true"
          class="v-icon notranslate material-icons theme--light"
        >
          {{ isFullScreen ? 'close_fullscreen' : 'fullscreen' }}
        </i>
      </span>
    </button>

    <div
      class="target"
      v-html="html"
    />
  </div>
</template>

<script>

export default {
  name: 'FullScreen',

  props: {
    html: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      isFullScreen: false,
    }
  },

  methods: {
    fullscreen () {
      this.isFullScreen = !this.isFullScreen

      document.documentElement.style.setProperty('overflow-y', this.isFullScreen ? 'hidden' : 'scroll')
    }
  }
}
</script>

<style lang="scss">
.target__wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.target__wrapper-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  background: #eee;

  .target > object[type="application/pdf"],
  .target > iframe {
    height: 100% !important;
  }
}

.target {
  flex-grow: 1;
}

.target__wrapper > button {
    margin-left: auto;
  }
</style>
